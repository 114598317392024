<template>
      <div class="cardItem-container">
      <div class="box" @click="handleJump()">
        <img class="left"  alt="">
        <div class="right">
          <div class="name">{{ "727测试展商2" }}</div>
          <div class="type">{{ "订单"}}</div>
          <div class="num" style="color: #858585">共{{'10' }}个展品</div>
        </div>
        <span class="iconfont wemeet-gengduo-shejiaomingpian"></span>
      </div>
    </div>
</template>
<script>
export default {
  name:'cardItem',
  props:{
   
  },
  components:{
  },
  data() {
    return {}
  },
  methods: {
    handleJump() {
      console.log('跳转');
      
    }
  },
  mounted() {}
}
</script>
<style scoped lang="less">
.cardItem-container {
  background-color: #fff;
  padding: 10px 17px;
  box-sizing: border-box;
  margin: 10px 0 0;
  display: flex;
  align-items: center;
  .box {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    .left {
      display: block;
      width: 44px;
      height: 44px;
      border-radius:50%;
      flex-shrink: 0;
      box-sizing: border-box;
      margin-right: 10px;
      background-color: #f5f5f5;
    }
    .right {
      display: flex;
      flex-flow: column wrap;
      flex: 1;
      margin-left: auto;
      .name {
        font-size: 16px;
        color: #262626;
        font-weight: 700;
      }
      .type {
        font-size: 12px;
        color: #8C8C8C;
      }
      .num {
        font-size: 12px;
        color: #8C8C8C;
      }
    }
    span {
      font-size: 20px;
      color:#D8D8D8;
      width: 21px;
      height: 21px;
      flex-shrink: 0;
      align-self: center;
      margin-left: auto;
    }
  }
}
  .title-box {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 17px 13px;
    box-sizing: border-box;
    .title {
      font-size: 16px;
      font-weight: 700;
      color: #292929;
    }

    .desc {
      margin-top: 10px;
      color: #858585;
      font-size: 12px;
    }
  }
</style>