<template>
	<div class="first-loading">
		<commonLoading></commonLoading>
		<div class="title">玩命加载中..</div>
	</div>
</template>

<script>
import commonLoading from './commonLoading.vue'
	export default {
		name:"firstLoading",
    components:{
      commonLoading
    },
		data() {
			return {
				
			};
		}
	}
</script>
<style scoped>
.first-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 6px;
  margin-top: 12px;
}
.first-loading .title {
  font-size: 12px;
  color: #9d9d9d;
  margin-left: 8px;
}
</style>