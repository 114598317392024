<template>
  <div class="actionBtn">
    <div class="box" @click="showPop">
			<span class="iconfont wemeet-fenxiang-shejiaomingpian"></span>
		</div>
  </div>
</template>
<script>
export default {
  name: 'shareMovable',
  props: {

  },
  components: {},
  data() {
    return {
      
    }
  },
  methods: {
    showPop() {
      console.log('分享');
      
    }
  },
  mounted() {},
}
</script>
<style lang="less" scoped>
  .actionBtn {
    position: absolute;
    right: 13px;
    top: -30px;
    z-index: 1000;
    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #fff;
      cursor: pointer;
      width: 40px;
      height: 40px;
      box-shadow: 0px 2px 30px 0px rgba(0,0,0,0.12);
      margin-left: 10px;
      span {
       font-size: 24px;
       color:#262626;
      }
    }
  }

</style>