<template>
  <van-popup class="pop-box p-relative" v-model="show" round position="bottom">
    <div class="title">
      联系我们
    </div>
    <div class="contacts">
      <commonContactItem v-for="(item, index) in arr" :key="index"
        :customClass="['pt-10 px-10 pb-20 ', index == 0 ? '' : 'mt-10']"></commonContactItem>
    </div>
    <div class="title">
      请联系我
    </div>
    <div class="form">
      <!-- 姓名 -->
      <div class="form-item">
        <div class="left" style="width: 100rpx;">
          <span style="color: #f52f3e">*</span>
          姓名：
        </div>
        <div class="right">
          <input class="" type="text"  placeholder="请填写您的姓名">
        </div>
      </div>
      <!-- 机构 -->
      <div class="form-item">
        <div class="left" style="width: 100rpx;">
          <span style="color: #f52f3e">*</span>
          机构：
        </div>
        <div class="right">
          <input class="" type="text" placeholder="请填写您的机构">
        </div>
      </div>
      <!-- 职位 -->
      <div class="form-item">
        <div class="left" style="width: 100rpx;">
          职位：
        </div>
        <div class="right">
          <input class="" type="text" placeholder="请填写您的职位">
        </div>
      </div>
      <!-- 电话 -->
      <div class="form-item">
        <div class="left" style="width: 100rpx;">
          电话：
        </div>
        <div class="right">
          <input class="" type="text"  placeholder="请填写您的电话">
        </div>
      </div>
      <!-- 邮箱 -->
      <div class="form-item">
        <div class="left" style="width: 100rpx;">
          <span style="color: #f52f3e">*</span>
          邮箱：
        </div>
        <div class="right">
          <input class="" type="text"  placeholder="请输入您的邮箱地址">
        </div>
      </div>
      <!-- 地区 -->
      <div class="form-item">
        <div class="left" style="width: 100rpx;">
          地区：
        </div>
        <div class="right" @click="handleChooseArea">
          <input class="" type="text" disabled  placeholder="选择地区">
        </div>
      </div>
      <!-- 地址 -->
      <div class="form-item">
        <div class="left" style="width: 100rpx;">
          地址：
        </div>
        <div class="right" >
          <input class="" type="text"   placeholder="请输入地址">
        </div>
      </div>
      <!-- 意向 -->
      <div class="form-item">
        <div class="left" style="width: 100rpx;">
          意向：
        </div>
        <div class="right">
          <van-radio-group 
            class="group" 
            v-model="radio" 
            icon-size="18px"
            @change="handleChange"
            >
            <van-radio class="group-item" name="1" checked-color="#00C4C9">单选框 1</van-radio>
            <van-radio class="group-item" name="2" checked-color="#00C4C9">单选框 2</van-radio>
            <van-radio class="group-item" name="3" checked-color="#00C4C9">单选框 3</van-radio>
            <van-radio class="group-item" name="4" checked-color="#00C4C9">单选框 4</van-radio>
          </van-radio-group>
        </div>
      </div>
      <div class="placeholder"></div>
      <div class="bottomBtn">
        <div class="btn" :class="isClickSubmit ? 'vivify duration-150 pulsate' : ''" @click="handleSummit">提交</div>
      </div>
    </div>
  </van-popup>
</template>
<script>
import commonContactItem from './commonContactItem.vue'
export default {
  name: 'vistorLinkUsPop',
  props: {},
  components: { commonContactItem },
  data() {
    return {
      show: false,
      arr: [1, 2, 3, 4, 5, 6],
      isClickSubmit: false,
      radio:'1',
    }
  },
  methods: {
    // 打开
    close() {
      this.show = false
    },
    open() {
      this.show = true
    },
    handleChooseArea() {
      console.log('选择地区');
    },
    handleChange(e) {
      console.log('意向选择eeee',e);
    },
    handleSummit() {
      console.log('提交');
      this.isClickSubmit = true;
      setTimeout(() => {
        this.isClickSubmit = false;
      }, 200)
    }
  },
  mounted() { }
}
</script>
<style scoped>
input {
  border: 0;
  outline: 0;
}

input::placeholder {
  color: #BFBFBF;
}

input::-webkit-input-placeholder {
  color: #BFBFBF;
}

input::-moz-input-placeholder {
  color: #BFBFBF;
}

input::-ms-input-placeholder {
  color: #BFBFBF;
}


.pop-box {
  max-height: 89vh;
  padding-bottom: 20px;
}

.title {
  display: flex;
  align-items: center;
  height: 53px;
  padding: 0 12px;
  box-sizing: border-box;
  width: 100%;
  color: #262626;
  font-weight: 700;
  font-size: 16px;
}

.contacts {
  overflow: auto;
  max-height: 300px;
  padding: 0 12px;
  box-sizing: border-box;
}

.form {
  padding: 0 12px;
}

.form-item {
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  min-height: 40px;
}

.left {
  display: flex;
  color: #262626;
  margin-right: 6px;
  font-size: 14px;
  padding-bottom: 5px;
  width: 50px;

}

.left span {
  color: #f52f3e;
}

.right {
  flex: 1;
  padding-bottom: 5px;
  border-bottom: 1px solid #E0E0E0;
}

.right input {
  flex: 1;
  color: #262626 !important;
  font-size: 14px;
}
.right .group {
  display: flex;
  flex-wrap: wrap;
}
.right .group .group-item{
  padding: 5px 10px 5px 0;
}
.placeholder {
  height: 80px;
}

.bottomBtn {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  height: 80px;
  padding: 10px 18px 0;
  box-sizing: border-box;
  background-color: #fff;
}

.bottomBtn .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  background-color: #00C4C9;
  height: 44px;
}</style>