<template>
	<div class="nav-box" >
		<block v-for="(item,index) in tabList" :key="index">
			<div class="item" :class="index != tabList.length -1 ? 'mr-30' : ''"
				 @click="handleTabSwitch(index)">
				<div class="title" :class="tabIndex === index ? 'active' : ''">
					{{ item.title }}
				</div>
				<div class="line mt-12 wmt_bg_color"
					:class="tabIndex === index ? 'visible animate__animated animate__faster animate__zoomIn' : 'invisible'"
					>
				</div>
			</div>
		</block>
	</div>
</template>

<script>
	export default {
		name: 'productNav',
		props: {
			tabIndex: {
				type: Number,
				default: 0
			},
			tabList: {
				type: Array,
				default: ()=> []
			},
		},
		data() {
			return {

			}
		},
		methods: {
			handleTabSwitch(index) {
				this.$emit("handleTabSwitch", index)
			},
		},
	}
</script>

<style lang="less" scoped>
  .nav-box {
    background-color: #fff;
    display: flex;
    align-items: flex-end;
    padding: 0 22px;
    margin-top: 10px;
    position: sticky;
    top: 0;
    z-index: 1000;
    height: 35px;
    border-bottom: 1px solid #E0E0E0;
  }
  .mr-30 {
    margin-right: 30px;
  }
  .cor-262626 {
    color: #262626
  }
  .item {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 29px;
    .title {
      color: #9E9E9E;
      &.active {
        color: #262626;
        font-weight: 700;
      }
    }
    .line {
      width: 15px;
      height: 3px;
      margin-top: 6px;
      background-color: #00C4C9;
    }
  }
  
</style>