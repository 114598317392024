<template>
   <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
    <van-swipe-item>1</van-swipe-item>
    <van-swipe-item>2</van-swipe-item>
    <van-swipe-item>3</van-swipe-item>
    <van-swipe-item>4</van-swipe-item>
  </van-swipe>
</template>
<script>
export default {
  name:'',
  props:{},
  components:{},
  data() {
    return {}
  },
  methods: {},
  mounted() {}
}
</script>
<style scoped>
  .my-swipe .van-swipe-item {
    background-color: #F5F5F5;
    height: 375px;    
  }
</style>