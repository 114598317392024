<template>
	<div class="nothing-box" :style="{ marginTop: top}" >
    <img :src="nodataIcon" alt="">
		<span :class="getCustomClass">{{text}}</span>
		<!-- <text class="d-inline-block" :class="getCustomClass">{{mintext}}</text> -->
		<!-- <div v-if="showLoginBtn" class="loginBtn" @click="login">
			登录/注册
		</div>
		<div v-if="showReg" class="loginBtn" @click="reg">
			登录/注册
		</div> -->
	</div>
</template>

<script>
	let obj = {
	  common: "https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/common-default-picture/common-no-data.png", // 通用的缺省
	  collect: "https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/common-default-picture/collect-no-data.png", // 收藏
	  coupon: "https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/common-default-picture/coupon-no-data.png", // 优惠劵
	  order: "https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/common-default-picture/order-no-data.png", // 订单
	  message: "https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/common-default-picture/message-no-data.png", // 消息/评论/回复
	  tag: "https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/common-default-picture/task-no-data.png", // 标签
	  nodata: "https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/common-default-picture/no-data.png", // 暂无数据
	  loadError: "https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/common-default-picture/load-error.png", // 加载失败
	  offline: "https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/common-default-picture/offline.png", // 离线/断网
	}
	export default{
		name: 'nothing',
		data(){
			return{}
		},
		props:{
			top:{
				type: String,
				default: '180px'
			},
			right:{
				type: String,
				default: '0px'
			},
			icon: {
			  type: String,
			  default: "common"
			},
			// img: {
			//   type: String,
			//   default: "https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/images/nothing/contactNo.png"
			// },
			text:{
				type: String,
				default: '暂无内容哦~'
			},
			mintext:{
				type: String,
				default: ''
			},
			showReg:{
				type: Boolean,
				default: false
			},
			showLoginBtn:{
				type: Boolean,
				default: false
			},
			customClass: { // 自定义class 以空格分割
				type: String,
				default: "px-55",
			},
		},
		computed: {
			// 自定义的class类
			getCustomClass() {
				return `${this.customClass}` 
			},
			nodataIcon() {
			  return obj[this.icon]
			}
		},
		methods:{
			login(){
				// let params = {
				// 	isUserRegister: true,
				// }
				// this.$nav.push('/subPackage/social/pages/user-edit-person-info/index', params)
			},
			reg(){
				// let params = {
				// 	isUserRegister: true,
				// }
				// this.$nav.push('/subPackage/social/pages/user-edit-person-info/index', params)
			}
		}
	}
</script>

<style scoped lang="less">
.nothing-box {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;
  width: 100%;
  span {
    display: inline-block;
    margin-top: 6px;
  }
}
	.loginBtn{
		margin: 25px auto 0;
		width: 105px;
		height: 37px;
		line-height: 37px;
		background: #00C4C9;
		border-radius: 10px;
		text-align: center;
		color: #fff;
		font-size: 14px;
	}
	.nothing{
		text-align: center;
		image{
			width: 165px;
			height: 165px;
		}
		text{
			font-size: 14px;
			color:#9E9E9E;
		}
	}
</style>