<template>
	<div class="commonProductItem" :class="customClass" @click="handleJump()">
    <img class="left" src="" alt="">
		<!-- <image class="br-12 mr-20 bg-F5F5F5 common-border border-box f-shrink" :src="item.cover" style="width:230rpx;height: 184rpx;">
		</image> -->
		<div class="right">
			<div class="title  text-ellipsis-1" >{{ item.productName || "浅蓝色牛仔裤男秋冬季2024新浅蓝色牛仔裤男秋冬季2024新."}}</div>
			<div class="remark" >类型：{{ '1212312321' }}</div>
			<div class="detail">
				<div class="list"
					 v-for="(v,i) in 5">{{'ce是按钮的'}}</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'commonProductItem',
		props: {
			item: {
				type: Object,
				default: () => ({}),
			},
			index: {
				type: Number,
				default: 0,
			},
			customClass: {
				type: String,
				default: 'mt-20',
			},
		},
		computed: {

		},
		data() {
			return {}
		},
		methods: {
			// 跳转产品详情页面
			handleJump() {
				// console.log(this.item)
        this.$router.push(`/productDetail`)
				// this.$nav.push('/subPackage/enterprise/pages/product-detail/index',{productCode: this.item.productCode });
			},
		},
	}
</script>
<style scoped>
.text-ellipsis-1 {
  /* overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap; */
  display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;

	overflow: hidden;
}
.commonProductItem {
  display: flex;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 10px;
}
.commonProductItem .left{
  display: block;
  width: 115px;
  height: 92px;
  margin-right: 10px;
  border-radius: 6px;
  flex-shrink: 0;
  background-color: #F5F5F5;
}
.right {
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  margin-left: auto;
}
.right  .title {
  font-size: 14px;
  color: #262626;
  width: 225px;
}
.right  .remark {
  margin-top: 5px;
  font-size: 12px;
  color: #8C8C8C；
}
.right  .detail .list{
  font-size: 12px;
  margin-top: 7px;
  border-radius: 4px;
  display: inline-block;
  color: #9E9E9E;
  margin-right: 5px;
  padding: 2px 4px;;
  border:1px solid #E0E0E0;
}
</style>