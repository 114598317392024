<template>
  <div class="product-detail-container  animate__animated animate__fadeIn animate__faster">
    <!-- 轮播图 -->
    <carousel></carousel>
    <!-- 顶部卡片 -->
    <topCard></topCard>
    <!-- 卡片item -->
    <cardItem></cardItem>
    <!-- 联系人 -->
    <div class="contacts-container">
      <block v-for="(item, index) in 2" :key="index">
        <commonContactItem customClass="p-10 mt-10"></commonContactItem>
      </block>
    </div>
    <!-- 属性 -->
    <productAttr></productAttr>
    <!-- 导航内容 -->
    <div class="nav-content-container">
      <productNav :tabIndex="tabIndex" :tabList="tabList" @handleTabSwitch="handleTabSwitch"></productNav>
      <template v-if="tabIndex == 0">
        <div class="animate__animated animate__fadeIn animate__faster">
          <productDetail></productDetail>
        </div>
      </template>
      <template v-if="tabIndex == 1">
        <div class="animate__animated animate__fadeIn animate__faster">
          <block v-for="(item, index) in 3" :key="index">
            <commonProductItem customClass="px-10 mt-20"></commonProductItem>
          </block>
        </div>
      </template>
    </div>
      <!-- 底部按钮 isClick控制是否置灰一级是否可以点击-->
		<commonBottomBtn @customBtnEvent="customBtnEvent">
			<template v-slot:oneBtnTitle>联系我们</template>
		</commonBottomBtn>
    <!-- 全局loading加载 -->
    <div v-if="firstLoading" class="bg-white p-fixed inset-0 d-flex flex-center"
				style="z-index: 1000000;">
				<firstLoading></firstLoading>
    </div>
    <!-- 联系人详情的弹窗 -->
		<vistorLinkUsPop ref="vistorLinkUsPopRef"></vistorLinkUsPop>
  </div>
</template>
<script>
import carousel from './components/carousel.vue'
import topCard from './components/topCard.vue'
import cardItem from './components/cardItem.vue'
import productAttr from './components/productAttr.vue'
import productDetail from './components/productDetail.vue'
import productNav from './components/productNav.vue'
import commonContactItem from '@/components/commonContactItem.vue'
import commonProductItem from '@/components/commonProductItem.vue'
import commonBottomBtn from '@/components/commonBottomBtn.vue'
import firstLoading from '@/components/firstLoading.vue'
import vistorLinkUsPop from '@/components/vistorLinkUsPop.vue'
export default {
  components:{
    carousel,
    topCard,
    cardItem,
    productAttr,
    productNav,
    productDetail,
    commonContactItem,
    commonProductItem,
    commonBottomBtn,
    vistorLinkUsPop,
    firstLoading,
  },
  data() {
    return {
      firstLoading: false,
      tabIndex: 0,
      tabList: [
        {
          id: 1,
          title: '详情',
        },
        // {
        //   id: 2,
        //   title: '附件',
        // },
        {
          id: 3,
          title: '其他',
        },
      ],
    }
  },
  methods: {
    customBtnEvent() {
      console.log("联系我们");
      this.$refs.vistorLinkUsPopRef.open()
    },
    handleTabSwitch(index) {
      this.tabIndex = index
    },
    handleCommonLoad() {
      this.firstLoading = true
      setTimeout(() => {
        this.firstLoading = false
      }, 300)
    }
  },
 mounted() {
    this.handleCommonLoad()
  },
}
</script>
<style lang="less" scoped>
/* 定位 - 固定顶部 */
.p-fixed {
  position: fixed;
}
.fixed-top {
	position: fixed;
	top: 0px;
	right: 0;
	left: 0;
	z-index: 1000;
}
.inset-0 {top: 0;bottom: 0;left: 0;right: 0;}
.product-detail-container {
  min-height: 100vh;
  background-color: #f5f5f5;
  box-sizing: border-box;
  padding-bottom: 30px;
}
.list-wrapper {
  box-sizing: border-box;
  padding: 0 10px;
}
.contacts-container {
  background-color: #fff;
  padding: 0 12px 10px;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 10px;
}
.nav-content-container {
  background-color: #fff;
  padding: 0 0 20px;
}
</style>