<template>
	<div :class="boxBgColor">
		<!-- 占位符 -->
		<div class="placeholder-div" :class="boxBgColor"></div>
		<template v-if="!isCustom">
		<div class="btn-box1" :class="isFixed ? 'fixed-bottom' : 'absolute-bottom'" style="">
			
				<!-- 展示一个Btn -->
				<div
				 v-if="btnNum==1"
				class="btnNum1"
				:class="[clickEffect,isOpacity,oneBtnClass]"
				@click="handleCustomEvent"><slot name="oneBtnTitle">下一步</slot></div>
				<!-- 展示两个Btn -->
				<div
				 v-if="btnNum==2"
				class="btnNum2-box"
				>
					<div 
					class="btn1 vivify duration-150"
					:class="[getLeftBtnClass]"
					@click="handleCancel"
					>
						<slot name="twoBtnLeftTitle">取消</slot>
					</div>
					<div 
					class="btn2" 
					:class="[clickEffect,isOpacity,getRightBtnClass]"
					@click="handleNext"
					><slot name="twoBtnRightTitle">下一步</slot></div>
				</div>
		</div>
		</template>
		<template v-else>
			<div class="btn-box1 fixed-bottom">
				<slot name="isCustom"></slot>
			</div>
		</template>
	</div>
</template>

<script>
	export default {
		name:"commonBottomBtn",
		components: {},
		props: {
			btnNum: {
				type: [Number],
				default: 1
			},
			boxBgColor :{
				type: [String],
				default: ""
			},
			isClick:{ // 是否可以点击
				type: Boolean,
				default: true
			},
			isFixed:{ // 是否可以点击
				type: Boolean,
				default: true
			},
			isCustom:{ // 自定义按钮
				type: Boolean,
				default: false
			},
			// 左边按钮默认的样式
			leftBtnDefaultClass:{
				type: [String],
				default: "cor-595959"
			},
			// 左边按钮的class样式
			leftBtnClass: {
				type: [String],
				default: "bg-F5F5F5"
			},
			// 右边按钮默认的样式
			rightBtnDefaultClass:{
				type: [String],
				default: "wmt_bg_color text-white flex-1"
			},
			// 右边按钮的class类样式
			rightBtnClass: {
				type: [String],
				default: ""
			},
			// 右边按钮的class类样式
			oneBtnClass: {
				type: [String],
				default: "wmt_bg_color"
			},
		},
		data() {
			return {
        clickEffect:""
      }
		},
		computed: {
			isOpacity() {
				let opacity = this.isClick ? `` : 'opacity-05'
				return `${opacity}`
			},
			getLeftBtnClass() {
				let leftBtnDefaultClass = this.leftBtnDefaultClass
				let leftBtnClass = this.leftBtnClass
				return `${leftBtnDefaultClass} ${leftBtnClass}`
			},
			getRightBtnClass() {
				let rightBtnDefaultClass = this.rightBtnDefaultClass
				let rightBtnClass = this.rightBtnClass
				return `${rightBtnDefaultClass} ${rightBtnClass}`
			},
		},
		methods: {
      clearAnimate() {
        if(this.isClick) {
          this.clickEffect = `vivify duration-150 pulsate`
           setTimeout(() => {
            this.clickEffect = ""
           }, 200);
        } else {
          this.clickEffect = ""
        }
      },
			handleCustomEvent() {
        this.clearAnimate()
				this.$emit("customBtnEvent")
			},		
			handleCancel() {
        this.clearAnimate()
				this.$emit("handleCancel")
			},		
			handleNext() {
        this.clearAnimate()
				this.$emit("handleNext")
			},
		},

	}
</script>

<style lang="less" scoped>
  .placeholder-div {
    height: 94px;
  }
  .btn-box1 {
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    height: 94px;
    border-top: 1px solid #F5F5F5;
    padding: 5px 18px;
    .btnNum1 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      border-radius: 10px;
      color: #fff;
      font-size: 17px;
    }
    .btnNum2-box {
      display: flex;
      align-items: center;
      height: 44px;
      border-radius: 10px;
      color: #fff;
      font-size: 17px;
      .btn1,.btn2 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        border-radius: 10px;
      }
      .btn1 {
        width: 100px;
        margin-right: 10px;
        flex-shrink: 0;
      }
      .btn2 {}
    }
  }
  .isCustom {
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    height: 94px;
    border-top: 1px solid #F5F5F5;
  }
</style>