<template>
	<div class="loader" :class="getCustomClass" :style="getCustomStyle"></div>
</template>

<script>
	export default {
	  name:"CommonLoading",
	  props: {
			width: { 
				type: [Number,String],
				default: 18,
			},
			height: { 
				type: [Number,String],
				default: 18,
			},
			borderWidth: { 
				type: [Number,String],
				default: 2,
			},
			customClass: { // 自定义class 以空格分割
				type: String,
				default: "",
			},
	  },
	  data() {
	  	return {}
	  },
	  computed: {
		    // 自定义的class类
		    getCustomClass() {
			  return `${this.customClass}`
		    },
			// 自定义最外层盒子的样式
			getCustomStyle() {
				let width =  `width: ${this.width}px;`
				let height = `height: ${this.height}px;`
				let borderWidth = `border-width: ${this.borderWidth}px;`
				return width + height + borderWidth
			},
	  },
	}
</script>

<style scoped>
.loader {
  border: 2px solid #00C4C9;
  border-left-color: transparent;
  border-radius: 50%;
}

.loader {
  border: 2px solid  #00C4C9;
  border-left-color: transparent;
}

.loader {
  border: 2px solid   #00C4C9;
  border-left-color: transparent;
  animation: spin89345 .4s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>