<template>
  <div class="productDetail-box">
    <div>
      <template v-if="details">
        <div v-html="details"></div>
      </template>
      <div v-else class="nodata">
        <nothing customClass="px-0" top="237px" :text="getText"></nothing>
      </div>
    </div>
  </div>
</template>
<script>
import nothing from '@/components/nothing.vue'
export default {
  name: 'productDetail',
  props: {},
  components: {nothing},
  data() {
    return {
      details: "1d1的后的hi大北农idb欧拉不多不对劲背带裤就定吧就定吧可db"
    }
  },
  methods: {},
  mounted() { }
}
</script>
<style lang="less" scoped>
.productDetail-box {
  padding: 12px 10px 60px;
  background-color: #fff;
}
.nodata {
  overflow: hidden;
  font-size: 13px;
  width: 100%;
  color: #858585;
}
</style>