<template>
    <div class="productAttr-container">
         <div class="title">属性</div>
        <div class="list" >
          <block v-for="(v, i) in attributeList" :key="i">
            <div  class="item"
              :class="changeIndex == i ? 'active' : 'default'">
              {{ "女装" }}</div>
          </block>
        </div>
    </div>
</template>
<script>
export default {
  name: 'productAttr',
  props: {
    attributeList:{
      type: Array,
      default:()=> [1,2,3,4]
    }
  },
  components: {},
  data() {
    return {
      changeIndex: 0
    }
  },
  methods: {},
  mounted() { }
}
</script>
<style lang="less" scoped>
  .productAttr-container {
    margin-top: 10px;
    overflow: hidden;
    background-color: #fff;
    box-sizing: border-box;
    padding: 8px 17px 15px;
    .title {
      font-size: 14px;
      font-weight: 700;
      color: #262626;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .item {
        font-size: 14px;
        padding: 5px 9px;
        margin: 10px 10px 0 0;
        font-size: 14px;
        border-radius: 6px;
        &.default {
          background-color: #F5F5F5;
          color: #9E9E9E;
        }
        &.active {
          background-color: #E5F9F9;
          color:#00C4C9
        }
      }
    }
  }
</style>