<template>
  <div class="cts-box" :class="customClass">
    <div class="introduction">
      <img class="left" src="" alt="">
			<!-- <img class="mr-14 flex-shrink bg-F5F5F5 rounded-circle f-shrink" :src="item.avatar" style="width: 96rpx;height: 96rpx"></image> -->
			<div class="right">
				<div class="top">
					<div class="name">
						{{item.name || '侯琴庆'}}
					</div>
					<div class="position">
						{{item.position || '商务销售总监'}}
					</div>
				</div>	
				<div class="center ">
					{{item.dutyDescription || '备注信息你好，我想和你交流一下'}}
				</div>
				<div class="bottom" @click="handleJumpDetail">
					负责<div>上海地区的业务对接工作</div>产品
				</div>
			</div>
		</div>
    		<!-- 打电话 -->
		<div class="call-phone" @click.stop="callPhone(item.phone)">
			<div class="iconfont">
				{{`\ue600`}}
			</div>
		</div>
		<!-- im -->
		<div class="im "   @click.stop="callIm()">
			<div class="iconfont">
				{{`\ue77a`}}
			</div>
		</div>
  </div>
</template>
<script>
export default {
  name:'commonContactItem',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    customItemClass: {
      type: String,
      default: '',
    },
    language:{
      type: String,
      default: 'CN',
    },
    customClass: {
      type: [String,Array],
      default: 'p-10',
    },
  },
  components:{},
  data() {
    return {}
  },
  methods: {
    handleJumpDetail() {
      this.$router.push("/groupDetail")
    }
  },
  mounted() {}
}
</script>
<style scoped>
  .p-10 {
    padding: 10px;
  }
  .px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pt-10 {
    padding-top: 10px;
  }
  .pb-10 {
    padding-bottom: 10px;
  }
  .pb-20 {
    padding-bottom: 20px;
  }
  .p-20 {
    padding: 20px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .cts-box {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
  }
  .introduction {
    display: flex;
    flex: 1;
  }
  .introduction .left {
    display: block;
    width: 48px;
    height: 48px;
    margin-right: 7px;
    flex-shrink: 0;
    background-color: #F5F5F5;
    border-radius: 50%;    
  }
  .introduction .right {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 3px;
  }
   .right .top {
    display: flex;
    flex-wrap: wrap;
  }
   .right .top .name{
    font-size: 18px;
    color: #262626;
    font-weight: 700;
    margin-right: 10px;
    line-height: 1.1;
  }
   .right .top .position{
    font-size: 12px;
    color: #8c8c8c;
    line-height: 1.6;
  }
   .right .center {
    margin-top: 3px;
    font-size: 12px;
    color: #8c8c8c;
  }
   .right .bottom {
    display: flex;
    margin-top: 3px;
    font-size: 12px;
    color: #8c8c8c;
  }
  .call-phone,.im {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: #F5F5F5;
    border-radius: 50%;
    margin-left: 10px;
  }
  .call-phone,.im {
    font-size: 18px;
    color: #00C4C9
  }
</style>