<template>
  <!-- 名称 -->
  <div class="title-box">
    <div class="title">{{  "美式vibe风裤子高街潮ins设计感满印提花牛仔裤复古hiphop直筒裤" }}</div>
    <div class="desc" v-if="productType">类型：{{ productType || '暂无' }}</div>
    <shareMovable></shareMovable>
  </div>
</template>
<script>
import shareMovable from '@/components/shareMovable.vue'
export default {
  name:'topCard',
  props:{
    productType: { 
      type: String,
      default:""
    },
    item: { 
      type: Object,
      default:()=>({})
    },
  },
  components:{
    shareMovable
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {}
}
</script>
<style scoped lang="less">
  .title-box {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 17px 13px;
    box-sizing: border-box;
    .title {
      font-size: 16px;
      font-weight: 700;
      color: #292929;
    }

    .desc {
      margin-top: 10px;
      color: #858585;
      font-size: 12px;
    }
  }
</style>